<template>
    <div class="footer">
        <div class="footer_wrapper">
            <div class="wrapper_header">
                <div class="left_logo">
                    <img src="../../../public/image/bg_img.jpg" alt="">
                </div>
                <div class="otherTwo">
                    <div class="center_detail">
                        <p>地址：杭州市西湖区余杭塘路866号浙江大学医学院综合楼8楼813室</p>
                        <p>邮编：310012</p>
                        <p>电话：86-0571-88208640</p>
                    </div>
                    <div class="friend_href">
                        <select name="" id="" style="border:1px solid gray;width:200px;height:40px">
                            <option value="浙江大学">浙江大学</option>
                            <option value="浙江大学">浙江大学</option>
                            <option value="浙江大学">浙江大学</option>
                            <option value="浙江大学">浙江大学</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="wrapper_bottom" >
                <p style="margin:0 auto;width:100%;max-width:1200px;color:#ffff"> Copyright © 2019 浙江大学基础医学院系统医学研究中心 技术支持 : 创高软件 后台管理 您是本站第 22615 位访客</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style scoped>
@media screen and (min-width: 1200px) {
    .footer {
        width: 100%;
        background-color: #003F88;
    }
    .footer_wrapper {
        width: 100%;
        margin: 0 auto;
    }
    .wrapper_header {
        height:173px;
        max-width: 1200px;
        display: flex;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;
    }
    .left_logo {
        width: 520px;
        height: 120px;
    }
    .left_logo img {
        width: 461px;
        height: 100%;
    }
    .center_detail {
        width:340px ;
        color: #fff;
    }
    .otherTwo{
            /* width: 1200px; */
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            padding: 20px 0px 0px 50px;
        } 
    .friend_href {

    }
    .wrapper_bottom {
        height:58px;
        width: 100%;
        line-height: 58px;
        border-top: 1px solid #295E9B;
        text-align: center;
    }
}

@media screen and (max-width: 1200px) {
    .wrapper_header {
        max-width: 1200px;
        display: flex;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        background-color: #003F88;
    }
    .left_logo {
        width: 520px;
        height: 120px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0px 0px 0px;
    }
    .otherTwo{
        /* width: 1200px; */
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 20px 0px 0px 50px;
    } 
    /* .left_logo img {
        margin: 0 auto;
    } */
    .left_logo img {
        width: 461px;
        height: 100%;
    }
    .center_detail {
        width:340px ;
        color: #fff;
    }
    .friend_href {

    }
    .wrapper_bottom {
        height:58px;
        width: 100%;
        line-height: 58px;
        border-top: 1px solid #295E9B;
        text-align: center;
        background-color: #003F88;
    }
}
</style>