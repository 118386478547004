<template>
  <div class="home">
    <div class="home_title">
      <div class="home_title_wrapper">
        <div
          style="
            width: 100%;
            height: 81px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 2px solid #003f88;
          "
        >
          <h3 style="color: #003f88; padding-left: 20px">重要研究进展</h3>
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              padding-right: 20px;
            "
            class="hovpin"
          >
            <small style="color: gray; margin-right: 5px">更多</small
            ><img
              width="25px"
              height="25px"
              src="../../public/image/plus.png"
              alt=""
            />
          </div>
        </div>
        <div class="cont_swiper">
          <el-carousel
            indicator-position="none"
            :interval="1000"
            arrow="always"
            :autoplay="false"
          >
            <el-carousel-item>
              <div class="swiper_list">
                <div>
                  <img
                    width="100%"
                    height="199px"
                    src="../../public/image/bg_img.jpg"
                    alt=""
                  />
                </div>
                <div>
                  <a style="width: 100%" height="77px"
                    >张岩课题组Cell合作发文首次揭示激活态多巴胺受体D1R和D2R配体</a
                  >
                </div>
                <div style="width: 100%">
                  <small
                    style="float: left; margin-left: 10px; padding-bottom: 20px"
                    >2021-02-12</small
                  >
                </div>
              </div>
              <div class="swiper_list">
                <div>
                  <img
                    width="100%"
                    height="199px"
                    src="../../public/image/bg_img.jpg"
                    alt=""
                  />
                </div>
                <div>
                  <a style="width: 100%" height="77px"
                    >张岩课题组Cell合作发文首次揭示激活态多巴胺受体D1R和D2R配体</a
                  >
                </div>
                <div style="width: 100%">
                  <small
                    style="float: left; margin-left: 10px; padding-bottom: 20px"
                    >2021-02-12</small
                  >
                </div>
              </div>
              <div class="swiper_list">
                <div>
                  <img
                    width="100%"
                    height="199px"
                    src="../../public/image/bg_img.jpg"
                    alt=""
                  />
                </div>
                <div>
                  <a style="width: 100%" height="77px"
                    >张岩课题组Cell合作发文首次揭示激活态多巴胺受体D1R和D2R配体</a
                  >
                </div>
                <div style="width: 100%">
                  <small
                    style="float: left; margin-left: 10px; padding-bottom: 20px"
                    >2021-02-12</small
                  >
                </div>
              </div>
              <div class="swiper_list">
                <div>
                  <img
                    width="100%"
                    height="199px"
                    src="../../public/image/bg_img.jpg"
                    alt=""
                  />
                </div>
                <div>
                  <a style="width: 100%" height="77px"
                    >张岩课题组Cell合作发文首次揭示激活态多巴胺受体D1R和D2R配体</a
                  >
                </div>
                <div style="width: 100%">
                  <small
                    style="float: left; margin-left: 10px; padding-bottom: 20px"
                    >2021-02-12</small
                  >
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="swiper_list">
                <div>
                  <img
                    width="100%"
                    height="199px"
                    src="../../public/image/bg_img.jpg"
                    alt=""
                  />
                </div>
                <div>
                  <a style="width: 100%" height="77px"
                    >张岩课题组Cell合作发文首次揭示激活态多巴胺受体D1R和D2R配体</a
                  >
                </div>
                <div style="width: 100%">
                  <small
                    style="float: left; margin-left: 10px; padding-bottom: 20px"
                    >2021-02-12</small
                  >
                </div>
              </div>
              <div class="swiper_list">
                <div>
                  <img
                    width="100%"
                    height="199px"
                    src="../../public/image/bg_img.jpg"
                    alt=""
                  />
                </div>
                <div>
                  <a style="width: 100%" height="77px"
                    >张岩课题组Cell合作发文首次揭示激活态多巴胺受体D1R和D2R配体</a
                  >
                </div>
                <div style="width: 100%">
                  <small
                    style="float: left; margin-left: 10px; padding-bottom: 20px"
                    >2021-02-12</small
                  >
                </div>
              </div>
              <div class="swiper_list">
                <div>
                  <img
                    width="100%"
                    height="199px"
                    src="../../public/image/bg_img.jpg"
                    alt=""
                  />
                </div>
                <div>
                  <a style="width: 100%" height="77px"
                    >张岩课题组Cell合作发文首次揭示激活态多巴胺受体D1R和D2R配体</a
                  >
                </div>
                <div style="width: 100%">
                  <small
                    style="float: left; margin-left: 10px; padding-bottom: 20px"
                    >2021-02-12</small
                  >
                </div>
              </div>
              <div class="swiper_list">
                <div>
                  <img
                    width="100%"
                    height="199px"
                    src="../../public/image/bg_img.jpg"
                    alt=""
                  />
                </div>
                <div>
                  <a height="77px"
                    >张岩课题组Cell合作发文首次揭示激活态多巴胺受体D1R和D2R配体</a
                  >
                </div>
                <div style="width: 100%">
                  <small
                    style="float: left; margin-left: 10px; padding-bottom: 20px"
                    >2021-02-12</small
                  >
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="cont_swiperSS">
          <div class="cont_swiperSS_wrap">
            <div class="swiper_listss">
              <div class="img_">
                <img
                  width="100%"
                  height="199px"
                  src="../../public/image/bg_img.jpg"
                  alt=""
                />
              </div>
              <div class="a_">
                <a
                  >张岩sdff课题组Cell合作发文首次揭示激活态多巴胺受体D1R和D2R配体</a
                >
              </div>
              <div style="width: 100%" class="time">
                <span
                  style="float: left; margin-left: 10px; padding-bottom: 20px"
                  >2021-02-12</span
                >
              </div>
            </div>
            <div class="swiper_listss">
              <div class="img_">
                <img
                  width="100%"
                  height="199px"
                  src="../../public/image/bg_img.jpg"
                  alt=""
                />
              </div>
              <div class="a_">
                <a
                  >张岩sdff课题组Cell合作发文首次揭示激活态多巴胺受体D1R和D2R配体</a
                >
              </div>
              <div style="width: 100%" class="time">
                <span
                  style="float: left; margin-left: 10px; padding-bottom: 20px"
                  >2021-02-12</span
                >
              </div>
            </div>
            <div class="swiper_listss">
              <div class="img_">
                <img
                  width="100%"
                  height="199px"
                  src="../../public/image/bg_img.jpg"
                  alt=""
                />
              </div>
              <div class="a_">
                <a
                  >张岩sdff课题组Cell合作发文首次揭示激活态多巴胺受体D1R和D2R配体</a
                >
              </div>
              <div style="width: 100%" class="time">
                <span
                  style="float: left; margin-left: 10px; padding-bottom: 20px"
                  >2021-02-12</span
                >
              </div>
            </div>
            <div class="swiper_listss">
              <div class="img_">
                <img
                  width="100%"
                  height="199px"
                  src="../../public/image/bg_img.jpg"
                  alt=""
                />
              </div>
              <div class="a_">
                <a
                  >张岩sdff课题组Cell合作发文首次揭示激活态多巴胺受体D1R和D2R配体</a
                >
              </div>
              <div style="width: 100%" class="time">
                <span
                  style="float: left; margin-left: 10px; padding-bottom: 20px"
                  >2021-02-12</span
                >
              </div>
            </div>
            <div class="swiper_listss">
              <div class="img_">
                <img
                  width="100%"
                  height="199px"
                  src="../../public/image/bg_img.jpg"
                  alt=""
                />
              </div>
              <div class="a_">
                <a
                  >张岩sdff课题组Cell合作发文首次揭示激活态多巴胺受体D1R和D2R配体</a
                >
              </div>
              <div style="width: 100%" class="time">
                <span
                  style="float: left; margin-left: 10px; padding-bottom: 20px"
                  >2021-02-12</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="info">
      <div class="info_wrapper">
        <div class="info_form">
          <div class="info_form_header">
            <div class="info_title">通知公告</div>
            <div class="info_more">
              <small style="color: gray; margin-right: 5px">更多</small
              ><img
                width="25px"
                height="25px"
                src="../../public/image/plus.png"
                alt=""
              />
            </div>
          </div>
          <div class="info_form_content">
            <div class="info_form_content_list">
              <div class="cont_time">
                <div style="border-bottom: 1px solid #ffff">02-02</div>
                <div style="">2021</div>
              </div>
              <div class="cont_detail">
                <a>基础医学学术年会——神经生理与脑疾病专场圆满落幕</a>
              </div>
            </div>
            <div class="info_form_content_list">
              <div class="cont_time">
                <div style="border-bottom: 1px solid #ffff">02-02</div>
                <div style="">2021</div>
              </div>
              <div class="cont_detail">
                <a>基础医学学术年会——神经生理与脑疾病专场圆满落幕</a>
              </div>
            </div>
            <div class="info_form_content_list">
              <div class="cont_time">
                <div style="border-bottom: 1px solid #ffff">02-02</div>
                <div style="">2021</div>
              </div>
              <div class="cont_detail">
                <a>基础医学学术年会——神经生理与脑疾病专场圆满落幕</a>
              </div>
            </div>
            <div class="info_form_content_list">
              <div class="cont_time">
                <div style="border-bottom: 1px solid #ffff">02-02</div>
                <div style="">2021</div>
              </div>
              <div class="cont_detail">
                <a>基础医学学术年会——神经生理与脑疾病专场圆满落幕</a>
              </div>
            </div>
            <div class="info_form_content_list">
              <div class="cont_time">
                <div style="border-bottom: 1px solid #ffff">02-02</div>
                <div style="">2021</div>
              </div>
              <div class="cont_detail">
                <a>基础医学学术年会——神经生理与脑疾病专场圆满落幕</a>
              </div>
            </div>
          </div>
        </div>

        <div class="teachers">
          <div class="info_form_header">
            <div class="info_title">通知公告</div>
            <div class="info_more">
              <small style="color: gray; margin-right: 5px">更多</small
              ><img
                width="25px"
                height="25px"
                src="../../public/image/plus.png"
                alt=""
              />
            </div>
          </div>
          <div class="teachers_bottom">
            <div class="teachers_bottom_left">
              <img
                style="height: 268px; width: 260px"
                src="../../public/image/bg_img.jpg"
                alt=""
              />
              <p style="width: 80%; color: #ffff">
                <b>荧光相关分子及其它单分子荧光技术的研发和应用</b>
              </p>
              <p
                style="width: 100%; color: #ffff; border-top: 1px solid gray"
              ></p>
              <p style="width: 80%; color: #ffff">【报告人】：黄韶辉</p>
              <p style="width: 80%; color: #ffff">
                【时间】：2020年11月09日10:00：00
              </p>
              <p style="width: 80%; color: #ffff">
                【报告人】：医学院综合楼205
              </p>
            </div>
            <div class="teachers_bottom_right">
              <div class="right_content_list">
                <div class="teachers_list_left">
                  <div
                    style="
                      width: 66px;
                      height: 38px;
                      line-height: 38px;
                      text-align: center;
                      background: #e3e3e3;
                      font-size: 18px;
                      color: #003f88;
                      border-top-left-radius: 5px;
                      border-top-right-radius: 5px;
                    "
                  >
                    <a>11-09</a>
                  </div>
                  <div
                    style="
                      width: 66px;
                      height: 36px;
                      line-height: 36px;
                      color: #fff;
                      text-align: center;
                      font-size: 16px;
                      background: #003f88;
                      border-bottom-left-radius: 5px;
                      border-bottom-right-radius: 5px;
                    "
                  >
                    <a>2020</a>
                  </div>
                </div>
                <div class="teachers_list_right">
                  <a>荧光相关光谱及其他单分子荧光技术的研发和应用</a>
                  <p style="font-size: 14px; color: gray; line-height: 10px">
                    【报告人】:程万金
                  </p>
                  <p style="font-size: 14px; color: gray; line-height: 10px">
                    【地点】:钉钉云讲座
                  </p>
                </div>
              </div>
              <div class="right_content_list">
                <div class="teachers_list_left">
                  <div
                    style="
                      width: 66px;
                      height: 38px;
                      line-height: 38px;
                      text-align: center;
                      background: #e3e3e3;
                      font-size: 18px;
                      color: #003f88;
                      border-top-left-radius: 5px;
                      border-top-right-radius: 5px;
                    "
                  >
                    <a>11-09</a>
                  </div>
                  <div
                    style="
                      width: 66px;
                      height: 36px;
                      line-height: 36px;
                      color: #fff;
                      text-align: center;
                      font-size: 16px;
                      background: #003f88;
                      border-bottom-left-radius: 5px;
                      border-bottom-right-radius: 5px;
                    "
                  >
                    <a>2020</a>
                  </div>
                </div>
                <div class="teachers_list_right">
                  <a>荧光相关光谱及其他单分子荧光技术的研发和应用</a>
                  <p style="font-size: 14px; color: gray; line-height: 10px">
                    【报告人】:程万金
                  </p>
                  <p style="font-size: 14px; color: gray; line-height: 10px">
                    【地点】:钉钉云讲座
                  </p>
                </div>
              </div>
              <div class="right_content_list">
                <div class="teachers_list_left">
                  <div
                    style="
                      width: 66px;
                      height: 38px;
                      line-height: 38px;
                      text-align: center;
                      background: #e3e3e3;
                      font-size: 18px;
                      color: #003f88;
                      border-top-left-radius: 5px;
                      border-top-right-radius: 5px;
                    "
                  >
                    <a>11-09</a>
                  </div>
                  <div
                    style="
                      width: 66px;
                      height: 36px;
                      line-height: 36px;
                      color: #fff;
                      text-align: center;
                      font-size: 16px;
                      background: #003f88;
                      border-bottom-left-radius: 5px;
                      border-bottom-right-radius: 5px;
                    "
                  >
                    <a>2020</a>
                  </div>
                </div>
                <div class="teachers_list_right">
                  <a>荧光相关光谱及其他单分子荧光技术的研发和应用</a>
                  <p style="font-size: 14px; color: gray; line-height: 10px">
                    【报告人】:程万金
                  </p>
                  <p style="font-size: 14px; color: gray; line-height: 10px">
                    【地点】:钉钉云讲座
                  </p>
                </div>
              </div>
              <div class="right_content_list">
                <div class="teachers_list_left">
                  <div
                    style="
                      width: 66px;
                      height: 38px;
                      line-height: 38px;
                      text-align: center;
                      background: #e3e3e3;
                      font-size: 18px;
                      color: #003f88;
                      border-top-left-radius: 5px;
                      border-top-right-radius: 5px;
                    "
                  >
                    <a>11-09</a>
                  </div>
                  <div
                    style="
                      width: 66px;
                      height: 36px;
                      line-height: 36px;
                      color: #fff;
                      text-align: center;
                      font-size: 16px;
                      background: #003f88;
                      border-bottom-left-radius: 5px;
                      border-bottom-right-radius: 5px;
                    "
                  >
                    <a>2020</a>
                  </div>
                </div>
                <div class="teachers_list_right">
                  <a>荧光相关光谱及其他单分子荧光技术的研发和应用</a>
                  <p style="font-size: 14px; color: gray; line-height: 10px">
                    【报告人】:程万金
                  </p>
                  <p style="font-size: 14px; color: gray; line-height: 10px">
                    【地点】:钉钉云讲座
                  </p>
                </div>
              </div>
              <div class="right_content_list">
                <div class="teachers_list_left">
                  <div
                    style="
                      width: 66px;
                      height: 38px;
                      line-height: 38px;
                      text-align: center;
                      background: #e3e3e3;
                      font-size: 18px;
                      color: #003f88;
                      border-top-left-radius: 5px;
                      border-top-right-radius: 5px;
                    "
                  >
                    <a>11-09</a>
                  </div>
                  <div
                    style="
                      width: 66px;
                      height: 36px;
                      line-height: 36px;
                      color: #fff;
                      text-align: center;
                      font-size: 16px;
                      background: #003f88;
                      border-bottom-left-radius: 5px;
                      border-bottom-right-radius: 5px;
                    "
                  >
                    <a>2020</a>
                  </div>
                </div>
                <div class="teachers_list_right">
                  <a>荧光相关光谱及其他单分子荧光技术的研发和应用</a>
                  <p style="font-size: 14px; color: gray; line-height: 10px">
                    【报告人】:程万金
                  </p>
                  <p style="font-size: 14px; color: gray; line-height: 10px">
                    【地点】:钉钉云讲座
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "Home",
  components: {
    // HelloWorld
  },
};
</script>
<style scoped>
.home {
  width: 100%;
}
.home_title {
  width: 100%;
  background-image: url("../../public/image/bg_img.jpg");
  background-repeat: no-repeat;
  background-position: center center;
}
.home_title_wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
}
.cont_swiper {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  display: block;
}
.el-carousel {
  width: 100%;
}
.el-carousel__item {
  width: 90%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 50px;
}

/* .el-carousel__item:nth-child(2n) {
    background-color: #d3dce6;
  }
  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  } */
.swiper_list:hover img {
  transform: scale(1.2);
}
.swiper_list {
  height: 100%;
  width: 265px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  background-color: #d3dce6;
}
.swiper_list:hover {
  cursor: pointer;
}
.swiper_list > div:nth-child(1) {
  overflow: hidden;
  margin-top: -11px;
  width: 265px;
  height: 199px;
}
.swiper_list > div:not(:first-child) {
  width: 90%;
}
.swiper_list img {
  transition: width 1s, height 1s, transform 1s;
  -moz-transition: width 1s, height 1s, -moz-transform 1s;
  -webkit-transition: width 1s, height 1s, -webkit-transform 1s;
  -o-transition: width 1s, height 1s, -o-transform 1s;
}
.swiper_list a {
  margin: 10px 0px 0px 0px;
}
.swiper_list a:hover {
  border-bottom: 1px solid #022f64;
  color: #022f64;
}
.info {
  width: 100%;
  background-color: #ffff;
  padding: 0px 0px 50px 0px;
}
.info_wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
}
.info_form {
  width: 388px;
  height: 577;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.info_form_header {
  width: 100%;
  padding: 25px 0 14px;
  border-bottom: 2px solid #003f88;
  display: flex;

  justify-content: space-between;
  align-items: center;
}
.info_form_header:hover {
  cursor: pointer;
}
.info_title {
  width: 140px;
  height: 40px;
  float: left;
  background-image: url("../../public/image/info_img.png");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 30% 100%;
  color: #003f88;
  text-align: right;
  line-height: 40px;
}
.hovpin:hover {
  cursor: pointer;
}
.info_more:hover {
  cursor: pointer;
}
.info_more {
  width: 58px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.info_form_content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.info_form_content_list {
  width: 95%;
  border-bottom: 1px solid gray;
  padding: 12px 5px 12px;
  border-bottom: 1px solid #ebebeb;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cont_time {
  width: 62px;
  height: 56px;
  padding: 7px 9px;
  background: #003f88;
  border-radius: 5px;
  color: #ffff;
  line-height: 30px;
  /* font-size: 18px; */
}
.cont_detail {
  font-size: 16px;
  color: #333;
  padding: 5px 0px 0px 5px;
}
.cont_detail a:hover {
  cursor: pointer;
  border-bottom: 1px solid #003f88;
  color: #003f88;
}
.teachers {
  width: 601px;
  height: 689px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.teachers_bottom {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.teachers_bottom:hover {
  cursor: pointer;
}
.teachers_bottom_left {
  position: relative;
  width: 260px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-color: #022f64;
  margin: 32px auto 0;
  transition: width 1.5s, height 1.5s, transform 1.5s;
  -moz-transition: width 1.5s, height 1.5s, -moz-transform 1.5s;
  -webkit-transition: width 1.5s, height 1.5s, -webkit-transform 1.5s;
  -o-transition: width 1.5s, height 1.5s, -o-transform 1.5s;
}
.teachers_bottom_left:hover {
  box-shadow: 0px 2px 10px gray;
  /* transition: all 0.2s ; */
  transition: all 0.25s;
  transform: translateY(-20px);
}
.right_content_list {
  padding: 10px 0 9px;
  border-bottom: 1px dotted #dbdbdb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.teachers_bottom_right {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.teachers_list_left {
  padding: 10px 10px 10px 10px;
}
.teachers_list_right {
  font-size: 16px;
  height: 90px;
}
.teachers_list_right a:hover {
  border-bottom: 1px solid #022f64;
  color: #003f88;
}
@media screen and (min-width: 1200px) {
  .teachers {
    margin-left: 50px;
  }
  .cont_swiperSS {
    display: none;
  }
}
@media screen and (max-width: 1200px) {
  .info {
    width: 100%;
    background-color: #ffff;
    padding: 0px 0px 50px 0px;
  }
  .info_wrapper {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
  .info_form {
    width: 100%;
    height: 577;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .info_form_header {
    margin-top: 50px;
    width: 100%;
    padding: 25px 0 14px 0px;
    border-bottom: 2px solid #003f88;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-left: 0px; */
  }
  .info_title {
    width: 140px;
    height: 40px;
    float: left;
    background-image: url("../../public/image/info_img.png");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 30% 100%;
    color: #003f88;
    text-align: right;
    line-height: 40px;
  }
  .hovpin:hover {
    cursor: pointer;
  }
  .info_more:hover {
    cursor: pointer;
  }
  .info_more {
    width: 58px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .info_form_content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .info_form_content_list {
    width: 100%;
    border-bottom: 1px solid gray;
    padding: 12px 5px 12px;
    border-bottom: 1px solid #ebebeb;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .cont_detail {
    font-size: 16px;
    color: #333;
    padding: 5px 0px 0px 15px;
  }
  .cont_detail a:hover {
    border-bottom: 1px solid #003f88;
    cursor: pointer;
  }
  .teachers {
    width: 100%;
    height: 689px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .teachers_bottom {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
  }
  .teachers_bottom:hover {
    cursor: pointer;
  }
  .teachers_bottom_left {
    position: relative;
    /* width: 260px; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background-color: #022f64;
    margin: 32px auto 0;
    transition: width 1.5s, height 1.5s, transform 1.5s;
    -moz-transition: width 1.5s, height 1.5s, -moz-transform 1.5s;
    -webkit-transition: width 1.5s, height 1.5s, -webkit-transform 1.5s;
    -o-transition: width 1.5s, height 1.5s, -o-transform 1.5s;
  }
  .teachers_bottom_left:hover {
    box-shadow: 0px 2px 10px gray;
    /* transition: all 0.2s ; */
    transition: all 0.25s;
    transform: translateY(-20px);
  }
  .right_content_list {
    width: 100%;
    padding: 10px 0px 9px 20px;
    border-bottom: 1px dotted #dbdbdb;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
  }
  .teachers_bottom_right {
    /* width: 100%; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }
  .teachers_list_left {
    padding: 10px 10px 10px 10px;
  }
  .teachers_list_right {
    font-size: 16px;
    height: 90px;
  }
  .teachers_list_right a:hover {
    border-bottom: 1px solid #022f64;
    color: #003f88;
  }
  .cont_swiper {
    display: none;
  }
  .cont_swiperSS {
    width: 100%;
    /* height:340px; */
    display: block;
    overflow: scroll;
    margin: 0 auto;
  }
  .cont_swiperSS_wrap {
    position: relative;
    white-space: nowrap;
    /* padding: 10px 0; */
    /* font-size: 0; */
    letter-spacing: 0;
    position: relative;
    color: #000 !important;
  }
  .swiper_listss img:hover {
    transform: scale(1.2);
  }
  .swiper_listss {
    width: 265px;
    margin: 0 4px;
    padding-bottom: 31px;
    box-shadow: 3px 3px 5px rgb(0 0 0 / 10%);
    background: #fff;
    border: 1px solid #d8d8d8;
    display: inline-block;
  }
  .swiper_listss > div:nth-child(1) {
    overflow: hidden;
    margin-top: -11px;
    width: 265px;
    height: 199px;
  }
  /* .swiper_listss>div:not(:first-child){
      width: 90%;
      display: block;
    } */
  .swiper_listss img {
    transition: width 1s, height 1s, transform 1s;
    -moz-transition: width 1s, height 1s, -moz-transform 1s;
    -webkit-transition: width 1s, height 1s, -webkit-transform 1s;
    -o-transition: width 1s, height 1s, -o-transform 1s;
  }
  .swiper_listss a {
    margin: 10px 0px 0px 0px;
  }
  .swiper_listss a:hover {
    border-bottom: 1px solid #022f64;
    color: #022f64;
  }
  .a_ {
    width: 90%;
    height: 40px;
    line-height: 28px;
    overflow: hidden;
    padding: 16px 12px 0;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .time {
    padding: 16px 12px 0;
    color: gray;
  }
  .img_ {
    display: block;
    width: 265px;
    height: 199px;
    overflow: hidden;
    /* background: #fafafa; */
  }
  body,
  div,
  ul,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  input,
  i,
  a,
  img,
  button {
    margin: 0;
    padding: 0;
    font-style: normal;
    vertical-align: top;
    border: none;
    outline: none;
    -webkit-tap-highlight-color: transparent;
  }
}
</style>

