<template>
  <div class="header">
    <div :class="a == 1 ? 'banner' : 'banner2'">
      <div class="ab">
        <div :class="a==1?'actbanner':''" @click="bgClick1"></div>
        <div :class="a==2?'actbanner':''" @click="bgClick"></div>
        <div></div>
      </div>
      <div class="logo_wrapper">
        <div class="logo">
          <img
            src="../../../public/image/bg_img2.jpg"
            alt=""
            style="opacity: 1"
          />
        </div>
        <div class="curor">
          <div class="wTabbar">
            <el-button
              @click="drawer = true"
              type="primary"
              style="
                margin-left: 16px;
                background-color: rgba(0, 0, 0, 0);
                border: none;
              "
            >
              <img src="../../../public/image/bg_img2.jpg" alt="" />
            </el-button>
            <!-- <img src="../../../public/image/bg_img2.jpg" alt=""> -->
          </div>
          <div class="canve">
            <img src="../../../public/image/bg_img.jpg" alt="" />
          </div>
          <div class="canve">
            <transition name="fade">
              <input
                v-show="toLong == true"
                :style="toLong == true ? 'width:180px;height:32px;' : ''"
                type="text"
                placeholder="请输入搜索关键词"
              />
            </transition>
            <img
              @click="soLong"
              src="../../../public/image/bg_img.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="tabber">
      <div
        style="
          max-width: 1200px;
          margin: 0 auto;
          display: flex;
          justify-content: space-around;
          align-items: center;
        "
      >
        <div
          v-for="(item, index) in tabberList"
          :key="index"
          class="tabberList"
        >
          <div
            @mouseenter="shouwmore(index)"
            @mouseleave="hiddenMoreFromUp(index)"
            class="titlename"
            @click="toNext(item)"
          >
            {{ item.name }}
          </div>
          <div @mouseleave="hiddenMore()" @mouseenter="intoChildIndex()">
            <div
              class="moreList"
              v-show="hoverItem == index"
              v-for="(items, indexs) in item.childItem"
              :key="indexs"
              @click="toChildNext(item, indexs)"
            >
              {{ items.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-drawer
      title="我是标题"
      :visible.sync="drawer"
      :with-header="false"
      direction="ltr"
      size="100%"
      :show-close="true"
    >
      <el-row class="tac">
        <el-col :span="12" style="width: 100%; color: #1b5597">
          <el-menu
            default-active="2"
            class="el-menu-vertical-demo"
            @open="handleOpen"
            @close="handleClose"
            text-color="#1B5597"
            :router="false"
          >
            <el-menu-item index="1">
              <!-- <i class="el-icon-menu"></i> -->
              <div
                slot="title"
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <a>首页</a> <a style="font-size: 20px" @click="closecT">×</a>
              </div>
            </el-menu-item>
            <el-submenu index="2">
              <template slot="title">
                <!-- <i class="el-icon-location"></i> -->
                <span>中心简介</span>
              </template>
              <el-submenu index="2-1">
                <template slot="title">选项1</template>
                <!-- <el-menu-item index="1-1-1">选项1</el-menu-item> -->
              </el-submenu>
              <el-submenu index="2-2">
                <template slot="title">选项2</template>
                <!-- <el-menu-item index="1-2-1">选项1</el-menu-item> -->
              </el-submenu>
              <el-submenu index="2-3">
                <template slot="title">选项3</template>
                <!-- <el-menu-item index="1-3-1">选项1</el-menu-item> -->
              </el-submenu>
              <el-submenu index="2-4">
                <template slot="title">选项4</template>
                <!-- <el-menu-item index="1-4-1">选项1</el-menu-item> -->
              </el-submenu>
            </el-submenu>
            <el-submenu index="3">
              <template slot="title">
                <!-- <i class="el-icon-location"></i> -->
                <span>研究组</span>
              </template>
              <el-submenu index="3-1">
                <template slot="title">选项1</template>
                <!-- <el-menu-item index="1-1-1">选项1</el-menu-item> -->
              </el-submenu>
            </el-submenu>
            <el-submenu index="4">
              <template slot="title">
                <!-- <i class="el-icon-location"></i> -->
                <span>研究成果</span>
              </template>
              <el-submenu index="4-1">
                <template slot="title">选项1</template>
                <!-- <el-menu-item index="1-1-1">选项1</el-menu-item> -->
              </el-submenu>
            </el-submenu>
            <el-submenu index="5">
              <template slot="title">
                <!-- <i class="el-icon-location"></i> -->
                <span>中心教学</span>
              </template>
              <el-submenu index="5-1">
                <template slot="title">选项1</template>
                <!-- <el-menu-item index="1-1-1">选项1</el-menu-item> -->
              </el-submenu>
            </el-submenu>
            <el-submenu index="6">
              <template slot="title">
                <!-- <i class="el-icon-location"></i> -->
                <span>研究生</span>
              </template>
              <el-submenu index="6-1">
                <template slot="title">选项1</template>
                <!-- <el-menu-item index="1-1-1">选项1</el-menu-item> -->
              </el-submenu>
            </el-submenu>
            <el-submenu index="7">
              <template slot="title">
                <!-- <i class="el-icon-location"></i> -->
                <span>人才招聘</span>
              </template>
              <el-submenu index="7-1">
                <template slot="title">选项1</template>
                <!-- <el-menu-item index="1-1-1">选项1</el-menu-item> -->
              </el-submenu>
              <el-submenu index="7-2">
                <template slot="title">选项2</template>
                <!-- <el-menu-item index="1-2-1">选项1</el-menu-item> -->
              </el-submenu>
              <el-submenu index="7-3">
                <template slot="title">选项3</template>
                <!-- <el-menu-item index="1-3-1">选项1</el-menu-item> -->
              </el-submenu>
              <el-submenu index="7-4">
                <template slot="title">选项4</template>
                <!-- <el-menu-item index="1-4-1">选项1</el-menu-item> -->
              </el-submenu>
            </el-submenu>
            <el-submenu index="8">
              <template slot="title">
                <!-- <i class="el-icon-location"></i> -->
                <span>生命科普</span>
              </template>
              <el-submenu index="8-1">
                <template slot="title">选项1</template>
                <!-- <el-menu-item index="1-1-1">选项1</el-menu-item> -->
              </el-submenu>
            </el-submenu>
            <el-submenu index="9">
              <template slot="title">
                <!-- <i class="el-icon-location"></i> -->
                <span>资源交流</span>
              </template>
              <el-submenu index="9-1">
                <template slot="title">选项1</template>
                <!-- <el-menu-item index="1-1-1">选项1</el-menu-item> -->
              </el-submenu>
            </el-submenu>
          </el-menu>
        </el-col>
      </el-row>
    </el-drawer>
  </div>
</template>
<script>
// import Swiper from "swiper"
import eventbus from "@/api/eventbus";
export default {
  data() {
    return {
      tabberList: [
        {
          name: "首页",
          path: "/",
        },
        {
          name: "中心简介",
          path: "/zxjj",
          childItem: [
            {
              name: "中心简介",
            },
            {
              name: "联系我们",
            },
          ],
        },
        {
          name: "研究组",
          path: "/yjz",
          childItem: [
            {
              name: "研究组",
            },
          ],
        },
        {
          name: "研究成果",
          path: "/yjcg",
          childItem: [
            {
              name: "研究成果",
            },
          ],
        },
        {
          name: "中心教学",
          path: "/zxjx",
          childItem: [
            {
              name: "中心教学",
            },
          ],
        },
        {
          name: "研究生",
          path: "/yjs",
          childItem: [
            {
              name: "研究生",
            },
          ],
        },
        {
          name: "人才招聘",
          path: "/rczp",
          childItem: [
            {
              name: "百人计划",
            },
            {
              name: "特遣招聘员",
            },
            {
              name: "博士后",
            },
          ],
        },
        {
          name: "生命科普",
          path: "/smkp",
          childItem: [
            {
              name: "生命科普",
            },
          ],
        },
        {
          name: "资源交流",
          path: "/zyjl",
          childItem: [
            {
              name: "资源交流",
            },
          ],
        },
      ],
      hoverItem: 0,
      toLong: false,
      drawer: false,
      screenWidth: "",
      timer: false,
      screenHeight: "",
      intoChild: false,
      a: 1,
    };
  },
  mounted() {
    this.screenWidth = document.body.clientWidth;
    // this.screenHeight = document.body.clientHeight;
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
        // this.screenHeight = document.body.clientHeight;
      })();
    };
  },
  methods: {
    bgClick() {
      this.a = 2;
    },
    bgClick1() {
      this.a = 1;
    },
    toNext(item) {
      // location.reload();
      // eventbus.$emit('headerTitle',item)
      window.sessionStorage.setItem("titleMessage", JSON.stringify(item));
      this.$router.push(item.path);
    },
    toChildNext(item, index) {
      eventbus.$emit("childindex", index);
      window.sessionStorage.setItem("titleMessage", JSON.stringify(item));
      this.hoverItem = -1;
      this.$router.push({
        path: item.path,
        query: {
          index: index,
        },
      });
    },
    shouwmore(index) {
      this.hoverItem = index;
      window.setTimeout(()=>{
        this.hoverItem = -1;
      },5000)
    },
    intoChildIndex() {
      this.intoChild = !this.intoChild;
    },
    hiddenMore() {
      this.hoverItem = -1;
    },
    hiddenMoreFromUp() {
      if (!this.intoChild) {
        // this.hoverItem = -1
      }
    },
    soLong() {
      this.toLong = !this.toLong;
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    closecT() {
      this.drawer = false;
    },
  },
  watch: {
    screenWidth(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenWidth = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          if (that.screenWidth > 1150) {
            that.drawer = false;
          }
          that.timer = false;
        }, 400);
      }
    },
  },
};
</script>
<style scoped>
/* @import "../../../node_modules/swiper/swiper.min.css"; */
.header {
  width: 100%;
}
.banner {
  animation: 2s opacity2 0s infinite;
  -webkit-animation: 2s opacity2 0s infinite;-moz-animation: 2s opacity2 0s infinite;
  position: relative;
  height: 315px;
  background-image: url("../../../public/image/bg_img.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  transition: width 1s, height 1s, transform 1s;
  -moz-transition: width 1s, height 1s, -moz-transform 1s;
  -webkit-transition: width 1s, height 1s, -webkit-transform 1s;
  -o-transition: width 1s, height 1s, -o-transform 1s;
}
@keyframes banner{
  0%{opacity:0}
  50%{opacity:.8;}
  /* 100%{opacity:0;} */
}
.banner2 {
  animation: 2s opacity2 0s infinite;
  -webkit-animation: 2s opacity2 0s infinite;-moz-animation: 2s opacity2 0s infinite;
  position: relative;
  height: 315px;
  background-image: url("../../../public/image/bg_img2.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  transition: width 1s, height 1s, transform 1s;
  -moz-transition: width 1s, height 1s, -moz-transform 1s;
  -webkit-transition: width 1s, height 1s, -webkit-transform 1s;
  -o-transition: width 1s, height 1s, -o-transform 1s;
}
 @keyframes banner2{
  0%{opacity:0}
  50%{opacity:.8;}
  /* 100%{opacity:0;} */
}
.tabber {
  display: block;
  width: 100%;
  height: 54px;
  background-color: #022f64;
  color: #ffff;
}
.ab {
  position: absolute;
  width: 100px;
  height: 49px;
  bottom: 0px;
  left: 50%;
  display: block;
  /* display: none; */
}
.ab:hover {
  cursor: pointer;
}
.ab > div {
  width: 10px;
  height: 10px;
  border: 1px solid gray;
  border-radius: 50%;
  float: left;
  margin: 10px;
}
.actbanner {
  background-color: #ffff;
}
.tabber:hover {
  cursor: pointer;
}
.tabberList {
  float: left;
  width: 11%;
  height: 54px;
  line-height: 54px;
  text-align: center;
  border-left: 0.5px solid #1f518c;
  border-right: 0.5px solid #1f518c;
  z-index: 9;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  transition: width 1s, height 1s, transform 1s;
  -moz-transition: width 1s, height 1s, -moz-transform 1s;
  -webkit-transition: width 1s, height 1s, -webkit-transform 1s;
  -o-transition: width 1s, height 1s, -o-transform 1s;
  /* overflow: hidden; */
}
.tabberList > div {
  width: 100%;
}
.tabberList:hover {
  background-color: #1f518c;
}
.tabberList:first-child {
  border: none;
}
.tabberList:last-child {
  border: none;
}
/* .tabberList:hover {
    background-color: #1F518C;
} */
.tabberList:nth-child(3) {
  /* height: 100px;  */
}
.logo_wrapper {
  width: 100%;
  max-width: 1200px;
  height: 100px;
  margin: 0 auto;
}
.logo {
  height: 80px;
  float: left;
  margin-top: 20px;
}
.logo img {
  width: 416px;
  height: 100%;
}
.curor {
  float: right;
  height: 80%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.curor img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.wTabbar {
  float: right;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}
.wTabbar img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.swiper-container {
  width: 500px;
  height: 300px;
  background-color: red;
}
.moreList {
  background-color: #1f518c;
  transition: width 1s, height 1s, transform 1s;
  -moz-transition: width 1s, height 1s, -moz-transform 1s;
  -webkit-transition: width 1s, height 1s, -webkit-transform 1s;
  -o-transition: width 1s, height 1s, -o-transform 1s;
}
.moreList:hover {
  background-color: #022f64;
}
.canve {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ffff;
  border-radius: 16px 16px 16px 16px;
  margin-left: 20px;
}
.canve input {
  width: 0px;
  height: 100%;
  color: #ffff;
  outline: none;
  border: 0.01px;
  background-color: rgba(0, 0, 0, 0);
  transition: width 1s, height 1s, transform 1s;
  -moz-transition: width 1s, height 1s, -moz-transform 1s;
  -webkit-transition: width 1s, height 1s, -webkit-transform 1s;
  -o-transition: width 1s, height 1s, -o-transform 1s;
}
.canve input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #ccc;
}
.canve input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #ccc;
}
.canve input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #ccc;
}
.canve input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #ccc;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active, 2.1.8 版本以下 */ {
  opacity: 0;
}
.el-drawer {
  display: none;
}
@media screen and (max-width: 1200px) {
  .logo_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .banner {
    height: 70px;
    width: 100%;
    background-image: url("../../../public/image/bg_img.jpg");
    background-repeat: no-repeat;
    background-position: center center;
  }
  .ab {
    position: absolute;
    width: 100px;
    height: 49px;
    bottom: 0px;
    left: 600px;
    display: none;
  }
  .ab:hover {
    cursor: pointer;
  }
  .ab > div {
    width: 6px;
    height: 6px;
    border: 1px solid gray;
    border-radius: 3px;
    float: left;
    margin: 10px;
  }
  .logo {
    height: 100%;
    float: left;
    margin-top: 0px;
  }
  .logo img {
    margin-top: 5px;
    height: 60px;
    width: 416px;
  }
  .curor {
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -10px;
  }
  .curor img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  .wTabbar {
    float: right;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    display: block;
  }
  .wTabbar img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-top: 5px;
    margin-right: -20px;
    border: 1px solid #ffff;
  }
  .tabber {
    display: none;
    width: 100%;
    height: 54px;
    background-color: #022f64;
    color: #ffff;
  }
  .el-drawer__body {
    width: 100%;
  }
  .el-drawer__container .el-drawer__open > div {
    width: 100%;
  }
  .el-drawer {
    width: 100% !important;
  }
  .ltr {
    width: 100% !important;
  }
  .el-menu-vertical-demo {
    color: #1b5597;
  }
}
</style>